
  import { Component, Prop, Vue } from "vue-property-decorator";

  @Component
  export default class Sparkline extends Vue {
    @Prop({ required: true }) data!: number[];

    stroke: number = 1;
    width: number = 126;
    height: number = 24;

    get shape(): string {
      const stroke = this.stroke;
      const width = this.width;
      const height = this.height - stroke * 2;
      const data = this.data || [];
      const highestPoint = Math.max(...data) + 1;
      const coordinates: { x: number; y: number }[] = [];
      const totalPoints = this.data.length - 1;

      data.forEach((item, n) => {
        const x = (n / totalPoints) * width + stroke;
        const y = height - (item / highestPoint) * height + stroke;
        coordinates.push({ x, y });
      });

      if (!coordinates[0]) {
        return (
          "M 0 " +
          this.stroke +
          " L 0 " +
          this.stroke +
          " L " +
          this.width +
          " " +
          this.stroke
        );
      }

      const path: string[] = coordinates.map(
        (point) => `L ${point.x} ${point.y}`
      );
      return `M${coordinates[0].x} ${coordinates[0].y} ${path.join(" ")}`;
    }

    get fillEndPath(): string {
      return `V ${this.height} L 4 ${this.height} Z`;
    }
  }
  